exports = module.exports = require("../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EzCM0QwY6Uot16SQtBTjd{table-layout:fixed;overflow-wrap:break-word}._1YuzWBd0vOdhbPuBI5Ly_V{font-family:monospace;font-size:12px;overflow-wrap:break-word;white-space:pre-wrap}", ""]);

// exports
exports.locals = {
	"fixedTable": "EzCM0QwY6Uot16SQtBTjd",
	"bodyPreview": "_1YuzWBd0vOdhbPuBI5Ly_V"
};